import { NextRouter } from "next/router";

import { OptionType } from "components/search/SearchBar";

const baseUrl: Record<string, string> = {
  local: "http://localhost:3000",
  production: "https://app.ribbon.ai",
  staging: "https://staging.ribbon.ai",
};

export const getExternalPath = (internalPath: string) => {
  const url = new URL(
    internalPath,
    baseUrl[process.env.NEXT_PUBLIC_ENVIRONMENT || "local"]
  );
  return url.href;
};

export const getHostAndPath = (url: string) => {
  const { host, pathname } = new URL(url);
  return `${host}${pathname}`;
};

export const getHomeUrl = () => "/";

export const getRecruitSettingsUrl = () => "/recruit/settings";

export const getDashboardUrl = (isFromOnboarding?: boolean) =>
  `/dashboard${isFromOnboarding ? "?onboarding=true" : ""}`;

export const getDocumentsUrl = () => "/documents";

export const getDocumentsListUrl = () => "/documents?list=true";

export const getCommunityUrl = () => "/community";

export const getExternalHomeUrl = () => getExternalPath(getHomeUrl());

export const getPostUrl = (postId: string) => `/posts/${postId}`;

export const getExternalPostUrl = (postId: string) =>
  getExternalPath(getPostUrl(postId));

type profileQueryParams = {
  isEditMode?: boolean;
  isProfileIntroMediaModalOpen?: boolean;
  getVerified?: boolean;
};

export const getUserProfileIdUrl = (user_id: string) => `/profiles/${user_id}`;

export const getProfileUrl = (
  handle: string,
  params: profileQueryParams = {
    isEditMode: false,
    isProfileIntroMediaModalOpen: false,
  }
) => {
  const url = `/p/${handle}`;
  const urlParams = new URLSearchParams();

  if (params?.isEditMode) {
    urlParams.append("isEditMode", "true");
  }

  if (params?.isProfileIntroMediaModalOpen) {
    urlParams.append("isProfileIntroMediaModalOpen", "true");
  }

  if (urlParams.toString()) {
    return `${url}?${urlParams.toString()}`;
  }

  return url;
};

export const getExternalProfileUrl = (handle: string) =>
  getExternalPath(getProfileUrl(handle));

export const getOrgProfileUrl = (orgId: string) => `/org/${orgId}`;

export const getExternalOrgProfileUrl = (orgId: string) =>
  getExternalPath(getOrgProfileUrl(orgId));

export const getEarningsUrl = () => `/earnings`;

export const getPersonalSettingsUrl = () => `/settings`;

const getPathRoot = (currentPath: string) =>
  currentPath.split("/").length >= 2
    ? `/${currentPath.split("/")[1]}`
    : undefined;

export const getSignInUrl = ({
  currentPath = "/",
  email = "",
  onSuccessfulAuthRedirect,
}: {
  currentPath?: string;
  email?: string;
  onSuccessfulAuthRedirect?: string;
}) => {
  let baseUrl = "/sign-in";
  const currentPathRoot = getPathRoot(currentPath);
  if (currentPathRoot === getRecruitUrl()) baseUrl = "/recruit/sign-in";
  if (currentPathRoot === getInterviewUrl())
    baseUrl = getInterviewUrlPath(currentPath, "sign-in");

  const urlParams = new URLSearchParams();
  if (email) {
    urlParams.append("email", email);
  }

  if (onSuccessfulAuthRedirect) {
    urlParams.append("onSuccessfulAuthRedirect", onSuccessfulAuthRedirect);
  }

  if (urlParams.toString()) {
    return `${baseUrl}?${urlParams.toString()}`;
  }
  return baseUrl;
};

export const getExternalSignInUrl = () => getExternalPath(getSignInUrl({}));

export const getSignOutUrl = () => `/sign-out`;

export const getOrgSettingsUrl = () => `/org-settings`;

export const getExternalOrgSettingsUrl = () =>
  getExternalPath(getOrgSettingsUrl());

export const getTeamDirectoryUrl = () => `/team-members`;

export const getTeamDirectoryMemberDetailUrl = (id: string) =>
  `/team-members/${id}`;

export const getTeamDirectoryPendingMemberDetailUrl = (email: string) =>
  `/team-members/?email=${encodeURIComponent(email)}`;

export const getChatUrl = (chatId: string) => `/chat/${chatId}`;

export const getChatOverviewUrl = () => `/chat`;

export const getNewChatUrl = (urlEncodedPrompt?: string, jobId?: string) =>
  `/chat/new${urlEncodedPrompt ? `?prompt=${urlEncodedPrompt}` : ""}${
    jobId ? `&jobId=${jobId}` : ""
  }`;

export const getEmailVerifiedUrl = ({
  currentPath = "/",
  onSuccessfulAuthRedirect,
}: {
  currentPath?: string;
  onSuccessfulAuthRedirect?: string;
}) => {
  let baseUrl = "/email-verified";
  const currentPathRoot = getPathRoot(currentPath);
  if (currentPathRoot === getRecruitUrl()) baseUrl = "/recruit/email-verified";
  if (currentPathRoot === getInterviewUrl())
    baseUrl = getInterviewUrlPath(currentPath, "email-verified");

  const urlParams = new URLSearchParams();
  if (onSuccessfulAuthRedirect) {
    urlParams.append("onSuccessfulAuthRedirect", onSuccessfulAuthRedirect);
  }

  if (urlParams.toString()) {
    return `${baseUrl}?${urlParams.toString()}`;
  }
  return baseUrl;
};

export const getVerifyEmailUrl = () => "/verify";

export const getInvitationUrl = () => "/invitation";

export const getResetPasswordUrl = () => "/reset-password";

export const getForgotPasswordUrl = () => "/forgot-password";

export const getNotificationsUrl = () => "/notifications";

export const getResumeUrl = () => `/resume`;

export const getPublicResumeUrl = () => `/resume/public`;

export const getExternalPublicResumeUrl = () =>
  getExternalPath(getPublicResumeUrl());

export const getJobsUrl = () => `/jobs`;

export const getJobsDetailUrl = (jobId: string) => `/jobs?jobId=${jobId}`;

export const getExternalJobsDetailUrl = (jobId: string) =>
  getExternalPath(getJobsDetailUrl(jobId));

export const getInterviewPlusUrl = () => `/interview-plus`;

export const getInterviewPlusPracticeUrl = () => `/interview-plus/practice`;

export const getInterviewPlusPracticeRoundUrl = (practiceRoundId: string) =>
  `/interview-plus/practice/${practiceRoundId}`;

export const getJobsCopilotUrl = () => `/copilot`;

export const getCheckEmailForResetPasswordUrl = () =>
  "/check-email-reset-password";

export const getLinkedInShareUrl = (url: string) => {
  return `https://www.linkedin.com/shareArticle?mini=false&url=${encodeURIComponent(
    url
  )}`;
};

export const getLinkedInJobsBoardUrl = () => {
  return "https://www.linkedin.com/jobs/collections/recommended/";
};

export const getIndeedJobsBoardUrl = () => {
  return "https://www.indeed.com/";
};

export const getWellfoundJobsBoardUrl = () => {
  return "https://wellfound.com/jobs";
};

export const getExternalPrivacyPolicyUrl = () =>
  "https://ribbon.ai/privacy-policy";

export const getExternalTermsOfServiceUrl = () =>
  "https://ribbon.ai/terms-of-service";

export const getExternalSignupUrl = (query?: { referrer: string }) => {
  const url = getExternalPath(getSignUpUrl({}));
  if (query) {
    const queryString = new URLSearchParams(query);
    return `${url}?${queryString.toString()}`;
  }
  return url;
};

export const getReferralShortUrl = (referrer: string) => {
  return `ribbon.ai/r/${referrer}`;
};

export const getOnboardingUrl = ({
  currentPath = "/",
  onSuccessfulOnboardingRedirect,
}: {
  currentPath?: string;
  onSuccessfulOnboardingRedirect?: string;
}) => {
  let baseUrl = "/onboarding";
  const currentPathRoot = getPathRoot(currentPath);
  if (currentPathRoot === getRecruitUrl()) baseUrl = "/recruit/onboarding";
  if (currentPathRoot === getInterviewUrl())
    baseUrl = getInterviewStartStepUrl(currentPath);

  const urlParams = new URLSearchParams();
  if (onSuccessfulOnboardingRedirect) {
    urlParams.append(
      "onSuccessfulOnboardingRedirect",
      onSuccessfulOnboardingRedirect
    );
  }

  if (urlParams.toString()) {
    return `${baseUrl}?${urlParams.toString()}`;
  }
  return baseUrl;
};

export const getExternalChromeExtensionUrl = () =>
  "https://chromewebstore.google.com/detail/ribbon-land-your-dream-jo/djpciihncdncnahnahjgmjpjcioakgan?hl=en";

export const convertToURL = (url: string) => {
  // Remove leading/trailing spaces
  url = url.trim();

  // Check if the string already starts with "https://www" or "http://www"
  if (url.startsWith("https://") || url.startsWith("http://")) {
    return url;
  }

  // Check if the string starts with "www."
  if (url.startsWith("www.")) {
    return "https://" + url;
  }

  // Otherwise, assume the string doesn't have any protocol or prefix
  return "https://" + url;
};

export const isValidURL = (str: string) => {
  try {
    const url = str?.trim();
    if (!url) {
      return true;
    }
    const pattern = new RegExp(
      "^([a-zA-Z]+:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d@%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$", // fragment locator
      "i"
    );
    return pattern.test(url);
  } catch (error) {
    return false;
  }
};

export const getDomainFromURL = (url: string) => {
  // Remove protocol (http, https, ftp, etc.)
  let domain = url.replace(/^(https?:\/\/|ftp:\/\/)/i, "");

  // Remove www subdomain if present
  domain = domain.replace(/^www\./i, "");

  // Get only the domain (excluding paths and query parameters)
  domain = domain.split("/")[0];

  return domain;
};

export const getSearchUrl = (filter: OptionType, keywords: string) => {
  return `/search?filter=${encodeURIComponent(
    filter.toLowerCase()
  )}&keywords=${encodeURIComponent(keywords)}`;
};

export const removeAllQueryParams = (router: NextRouter) =>
  router.replace(router.asPath.split("?")[0], undefined, { shallow: true });

export const replaceQueryParams = (
  router: NextRouter,
  param: string,
  newValue: string
) => {
  const [base, search] = router.asPath.split("?");
  const searchParams = new URLSearchParams(search);
  searchParams.set(param, newValue);
  router.replace(`${base}?${searchParams.toString()}`, undefined, {
    shallow: true,
  });
};

export const getGmailUrl = () => "https://www.gmail.com";

export const getOutlookUrl = () => "https://www.outlook.com";

export const getICloudMailUrl = () => "https://www.icloud.com/mail";

type NestedRecord = { [key: string]: string | string[] | NestedRecord };

export const parseQueryParams = (queryParams: string) => {
  const params = new URLSearchParams(queryParams);
  const paramsObject: NestedRecord = {};

  // Iterate over the parameters
  for (const [key, value] of params.entries()) {
    // keys can have a nested structure, we split the keys at the dot
    const keys = key.split(".");
    // create a ref to handle assigning nested keys
    let currentObject = paramsObject;

    // handle nested objects by iterating over each key
    keys.forEach((k, index) => {
      if (index === keys.length - 1) {
        if (currentObject[k] === undefined) {
          // if it's the first time the key is encountered, assign the value
          currentObject[k] = value;
        } else if (Array.isArray(currentObject[k])) {
          // if it's already an array, just push the new value
          (currentObject[k] as string[]).push(value);
        } else {
          // if it's a single value, convert it to an array with the new value
          currentObject[k] = [currentObject[k] as string, value];
        }
      } else {
        // otherwise create the nested object and reassign currentObject
        if (!currentObject[k]) currentObject[k] = {};
        currentObject = currentObject[k] as NestedRecord;
      }
    });
  }

  return paramsObject;
};

export const formatQueryParams = (paramsObject: NestedRecord) => {
  const getQueryParts = (paramsObject: NestedRecord, currentString = "") => {
    const result: string[] = [];
    for (const key of Object.keys(paramsObject).sort()) {
      const newKey = currentString ? `${currentString}.${key}` : key;
      if (typeof paramsObject[key] === "string") {
        result.push(`${newKey}=${paramsObject[key]}`);
      } else if (Array.isArray(paramsObject[key])) {
        (paramsObject[key] as string[]).sort().forEach((value) => {
          result.push(`${newKey}=${value}`);
        });
      } else {
        result.push(
          ...getQueryParts(paramsObject[key] as NestedRecord, newKey)
        );
      }
    }
    return result;
  };
  const queryParts = getQueryParts(paramsObject);
  return queryParts.join("&");
};

export const getInterviewUrl = () => "/interview";
export const getRecruitUrl = () => "/recruit";

export const getCreateNewInterviewUrl = () =>
  `${getRecruitUrl()}?createNewInterview=true`;

export const getPaymentConfirmedUrl = () => "/recruit/payment-confirmed";
export const getRecruitInterviewsUrl = () => "/recruit/interviews";
export const getRecruitIntegrationsUrl = () => "/recruit/integrations";
export const getRecruitInterviewDetailUrl = (interviewId: string) =>
  `/recruit/interviews/${interviewId}`;

export const getRecruitInterviewAllCandidatesUrl = (interviewId: string) =>
  `/recruit/interviews/${interviewId}/all`;

export const getRecruitCandidateDetailUrl = (
  interviewId: string,
  candidateId: string
) => `/recruit/interviews/${interviewId}/${candidateId}`;

export const getCandidateInterviewUrl = (interviewId: string) =>
  `/interview/${interviewId}`;

export const getExternalCandidateInterviewUrl = (interviewId: string) =>
  getExternalPath(getCandidateInterviewUrl(interviewId));

export const getExternalCandidateInterviewDisplayUrl = (interviewId: string) =>
  getExternalCandidateInterviewUrl(interviewId)
    .replace("https://", "")
    .replace("http://", "");

export const getSignUpUrl = ({
  currentPath = "/",
  onSuccessfulAuthRedirect,
}: {
  currentPath?: string;
  onSuccessfulAuthRedirect?: string;
}) => {
  let baseUrl = "/sign-up";
  const currentPathRoot = getPathRoot(currentPath);
  if (currentPathRoot === getRecruitUrl()) baseUrl = "/recruit/sign-up";
  if (currentPathRoot === getInterviewUrl())
    baseUrl = getInterviewUrlPath(currentPath, "sign-up");

  const urlParams = new URLSearchParams();
  if (onSuccessfulAuthRedirect) {
    urlParams.append("onSuccessfulAuthRedirect", onSuccessfulAuthRedirect);
  }

  if (urlParams.toString()) {
    return `${baseUrl}?${urlParams.toString()}`;
  }
  return baseUrl;
};
const getInterviewUrlPath = (currentPath: string, lastSegment: string) => {
  const [baseUrl] = currentPath.split("?"); // remove queryparams
  const segments = baseUrl.split("/"); // keep only interview/:interview-id
  return [segments[0], segments[1], segments[2], lastSegment].join("/");
};

export const getInterviewStartStepUrl = (currentPath: string) => {
  return getInterviewUrlPath(currentPath, "?step=documents");
};

export const sitemapUrls = [
  getExternalHomeUrl(),
  getExternalSignupUrl(),
  getExternalSignInUrl(),
  getExternalPublicResumeUrl(),
];

export const getMyInterviewsUrl = () => "/my-interviews";

export const getMyInterviewsDetailUrl = (id: string) => `/my-interviews/${id}`;

export const getRecruitJoinTeamBaseUrl = () => "/recruit/join-team";

export const getRecruitJoinTeamUrl = (teamId: string) =>
  `${getRecruitJoinTeamBaseUrl()}?teamId=${teamId}`;
