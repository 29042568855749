import { configureStore } from "@reduxjs/toolkit";
import accountProfileReducer from "store/accountProfileSlice";
import atsJobsReducer from "store/atsJobsSlice";
import chatReducer from "store/chatSlice";
import documentsReducer from "store/documentsSlice";
import earningReducer from "store/earningsSlice";
import followReducer from "store/followSlice";
import invitesReducer from "store/invitesSlice";
import jobsReducer from "store/jobsSlice";
import notificationsReducer from "store/notificationsSlice";
import orgMemberReducer from "store/orgMemberSlice";
import orgRolesAndLimitsReducer from "store/orgRolesAndLimitsSlice";
import planReducer from "store/planSlice";
import postIndexReducer from "store/postIndexSlice";
import postsReducer from "store/postsSlice";
import practiceRoundReducer from "store/practiceRoundSlice";
import recruitingTeamReducer from "store/recruitingTeamSlice";
import resumeFeedbackReducer from "store/resumeFeedbackSlice";
import sysAdminReducer from "store/sysAdminSlice";

import { AccountProfile, UserPlan } from "apis/accountProfile";
import { IntegrationJob } from "apis/atsIntegration";
import { Chat, ChatMessage } from "apis/chat";
import { Document } from "apis/document";
import { Earning } from "apis/earningTransaction";
import { UserJobOverview, UserJobs } from "apis/jobs";
import { Notification } from "apis/notifications";
import { OrgMembers } from "apis/orgs";
import { OrgRolesAndLimits } from "apis/orgs";
import { Post } from "apis/posts";
import { RecruitingTeam, RecruitingTeamMembers } from "apis/recruitingTeam";
import { ResumeFeedback } from "apis/resumeFeedback";
import { PlanLimits } from "apis/ribbonPro";

import { sysAdminRoles } from "./sysAdminSlice";

export type Action<T> = {
  payload: T;
  type: string;
};

export type State = {
  atsJobs: { value?: IntegrationJob[] };
  chat: {
    chats: Record<string, { chat: Chat; chatMessages: ChatMessage[] }>;
    assistantMessagesCount: { receivedCount?: number };
  };
  documents: { value?: Document[] };
  earning: { value: Earning };
  invites: { value: { sentCount?: number } };
  posts: { value: Post[] };
  plan: { value: Record<UserPlan, PlanLimits> };
  practiceRound: { value: { seenCount?: number } };
  postIndex: { value: Record<string, Post> };
  accountProfile: { value: AccountProfile };
  jobs: {
    value: UserJobOverview & UserJobs;
    pageNumber: {
      save: number;
      view: number;
      apply: number;
    };
  };
  notifications: { value: Notification[]; isLoaded: boolean };
  orgMembers: { value: OrgMembers };
  orgRolesAndLimits: { value: Record<string, OrgRolesAndLimits> };
  followedUserIds: { value: string[] };
  recruitingTeam: { team?: RecruitingTeam; members?: RecruitingTeamMembers };
  resumeFeedback: { counts: { seenCount?: number }; latest?: ResumeFeedback };
  sysAdminRoles: { value: sysAdminRoles[] };
};

export default configureStore({
  reducer: {
    atsJobs: atsJobsReducer,
    chat: chatReducer,
    documents: documentsReducer,
    earning: earningReducer,
    invites: invitesReducer,
    posts: postsReducer,
    postIndex: postIndexReducer,
    plan: planReducer,
    practiceRound: practiceRoundReducer,
    accountProfile: accountProfileReducer,
    jobs: jobsReducer,
    notifications: notificationsReducer,
    orgMembers: orgMemberReducer,
    orgRolesAndLimits: orgRolesAndLimitsReducer,
    followedUserIds: followReducer,
    recruitingTeam: recruitingTeamReducer,
    resumeFeedback: resumeFeedbackReducer,
    sysAdminRoles: sysAdminReducer,
  },
});
