import { createSlice } from "@reduxjs/toolkit";

import { RecruitingTeam, RecruitingTeamMembers } from "apis/recruitingTeam";

import { Action, State } from "./store";

export const recruitingTeamSlice = createSlice({
  name: "recruitingTeam",
  initialState: {
    team: undefined as RecruitingTeam | undefined,
    members: undefined as RecruitingTeamMembers | undefined,
  },
  reducers: {
    addTeam: (state, action: Action<RecruitingTeam>) => {
      state.team = action.payload;
    },
    addTeamMembers: (state, action: Action<RecruitingTeamMembers>) => {
      state.members = action.payload;
    },
    removeTeamMember: (state, action: Action<string>) => {
      if (!state.members) return;
      state.members.teamMembers = state.members.teamMembers.filter(
        ({ id }) => id != action.payload
      );
    },
  },
});

export const { addTeam, addTeamMembers, removeTeamMember } =
  recruitingTeamSlice.actions;

export const selectRecruitingTeam = (
  state: State
): RecruitingTeam | undefined => {
  return state.recruitingTeam.team;
};

export const selectRecruitingTeamMembers = (
  state: State
): RecruitingTeamMembers | undefined => {
  return state.recruitingTeam.members;
};

export default recruitingTeamSlice.reducer;
